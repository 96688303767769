* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}

.inner-image > img {
  margin-left: -100px;
}
